var variables = {
  data () {
    return {
      paymentStatus: [
        {
          key: 'PAID',
          value: 'Pago'
        },
        {
          key: 'PENDING',
          value: 'Pendente'
        },
        {
          key: 'PROCESSING',
          value: 'Processando'
        },
        {
          key: 'AUTHORIZED',
          value: 'Autorizado'
        },
        {
          key: 'REFUNDED',
          value: 'Estornado'
        },
        {
          key: 'WAITING_PAYMENT',
          value: 'Aguardando pagamento'
        },
        {
          key: 'PENDING_REFUND',
          value: 'Aguardando estorno'
        },
        {
          key: 'REFUSED',
          value: 'Recusado'
        },
        {
          key: 'CHARGEDBACK',
          value: 'Chargeback'
        },
        {
          key: 'EXPIRED',
          value: 'Expirado'
        },
        {
          key: 'CANCELED',
          value: 'Cancelado'
        }
      ],
      countries: [
        { code: 'AF', name: 'Afeganistão' },
        { code: 'AX', name: 'Ilhas Åland' },
        { code: 'AL', name: 'Albânia' },
        { code: 'DZ', name: 'Argélia' },
        { code: 'AS', name: 'Samoa Americana' },
        { code: 'AD', name: 'Andorra' },
        { code: 'AO', name: 'Angola' },
        { code: 'AI', name: 'Anguila' },
        { code: 'AQ', name: 'Antártica' },
        { code: 'AG', name: 'Antígua e Barbuda' },
        { code: 'AR', name: 'Argentina' },
        { code: 'AM', name: 'Armênia' },
        { code: 'AW', name: 'Aruba' },
        { code: 'AU', name: 'Austrália' },
        { code: 'AT', name: 'Áustria' },
        { code: 'AZ', name: 'Azerbaijão' },
        { code: 'BS', name: 'Bahamas' },
        { code: 'BH', name: 'Bahrein' },
        { code: 'BD', name: 'Bangladesh' },
        { code: 'BB', name: 'Barbados' },
        { code: 'BY', name: 'Bielorrússia' },
        { code: 'BE', name: 'Bélgica' },
        { code: 'BZ', name: 'Belize' },
        { code: 'BJ', name: 'Benin' },
        { code: 'BM', name: 'Bermudas' },
        { code: 'BT', name: 'Butão' },
        { code: 'BO', name: 'Bolívia' },
        { code: 'BA', name: 'Bósnia e Herzegovina' },
        { code: 'BW', name: 'Botsuana' },
        { code: 'BV', name: 'Ilha Bouvet' },
        { code: 'BR', name: 'Brasil' },
        { code: 'IO', name: 'Território Britânico do Oceano Índico' },
        { code: 'BN', name: 'Brunei' },
        { code: 'BG', name: 'Bulgária' },
        { code: 'BF', name: 'Burquina Faso' },
        { code: 'BI', name: 'Burundi' },
        { code: 'KH', name: 'Camboja' },
        { code: 'CM', name: 'Camarões' },
        { code: 'CA', name: 'Canadá' },
        { code: 'CV', name: 'Cabo Verde' },
        { code: 'KY', name: 'Ilhas Cayman' },
        { code: 'CF', name: 'República Centro-Africana' },
        { code: 'TD', name: 'Chade' },
        { code: 'CL', name: 'Chile' },
        { code: 'CN', name: 'China' },
        { code: 'CX', name: 'Ilha Christmas' },
        { code: 'CC', name: 'Ilhas Cocos (Keeling)' },
        { code: 'CO', name: 'Colômbia' },
        { code: 'KM', name: 'Comores' },
        { code: 'CG', name: 'Congo' },
        { code: 'CD', name: 'República Democrática do Congo' },
        { code: 'CK', name: 'Ilhas Cook' },
        { code: 'CR', name: 'Costa Rica' },
        { code: 'CI', name: 'Costa do Marfim' },
        { code: 'HR', name: 'Croácia' },
        { code: 'CU', name: 'Cuba' },
        { code: 'CY', name: 'Chipre' },
        { code: 'CZ', name: 'República Tcheca' },
        { code: 'DK', name: 'Dinamarca' },
        { code: 'DJ', name: 'Djibouti' },
        { code: 'DM', name: 'Dominica' },
        { code: 'DO', name: 'República Dominicana' },
        { code: 'EC', name: 'Equador' },
        { code: 'EG', name: 'Egito' },
        { code: 'SV', name: 'El Salvador' },
        { code: 'GQ', name: 'Guiné Equatorial' },
        { code: 'ER', name: 'Eritreia' },
        { code: 'EE', name: 'Estônia' },
        { code: 'ET', name: 'Etiópia' },
        { code: 'FK', name: 'Ilhas Malvinas' },
        { code: 'FO', name: 'Ilhas Faroé' },
        { code: 'FJ', name: 'Fiji' },
        { code: 'FI', name: 'Finlândia' },
        { code: 'FR', name: 'França' },
        { code: 'GF', name: 'Guiana Francesa' },
        { code: 'PF', name: 'Polinésia Francesa' },
        { code: 'TF', name: 'Territórios Franceses do Sul' },
        { code: 'GA', name: 'Gabão' },
        { code: 'GM', name: 'Gâmbia' },
        { code: 'GE', name: 'Geórgia' },
        { code: 'DE', name: 'Alemanha' },
        { code: 'GH', name: 'Gana' },
        { code: 'GI', name: 'Gibraltar' },
        { code: 'GR', name: 'Grécia' },
        { code: 'GL', name: 'Groenlândia' },
        { code: 'GD', name: 'Granada' },
        { code: 'GP', name: 'Guadalupe' },
        { code: 'GU', name: 'Guam' },
        { code: 'GT', name: 'Guatemala' },
        { code: 'GG', name: 'Guernsey' },
        { code: 'GN', name: 'Guiné' },
        { code: 'GW', name: 'Guiné-Bissau' },
        { code: 'GY', name: 'Guiana' },
        { code: 'HT', name: 'Haiti' },
        { code: 'HM', name: 'Ilha Heard e Ilhas McDonald' },
        { code: 'VA', name: 'Vaticano' },
        { code: 'HN', name: 'Honduras' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'HU', name: 'Hungria' },
        { code: 'IS', name: 'Islândia' },
        { code: 'IN', name: 'Índia' },
        { code: 'ID', name: 'Indonésia' },
        { code: 'IR', name: 'Irã' },
        { code: 'IQ', name: 'Iraque' },
        { code: 'IE', name: 'Irlanda' },
        { code: 'IM', name: 'Ilha de Man' },
        { code: 'IL', name: 'Israel' },
        { code: 'IT', name: 'Itália' },
        { code: 'JM', name: 'Jamaica' },
        { code: 'JP', name: 'Japão' },
        { code: 'JE', name: 'Jersey' },
        { code: 'JO', name: 'Jordânia' },
        { code: 'KZ', name: 'Cazaquistão' },
        { code: 'KE', name: 'Quênia' },
        { code: 'KI', name: 'Quiribati' },
        { code: 'KP', name: 'Coreia do Norte' },
        { code: 'KR', name: 'Coreia do Sul' },
        { code: 'KW', name: 'Kuwait' },
        { code: 'KG', name: 'Quirguistão' },
        { code: 'LA', name: 'Laos' },
        { code: 'LV', name: 'Letônia' },
        { code: 'LB', name: 'Líbano' },
        { code: 'LS', name: 'Lesoto' },
        { code: 'LR', name: 'Libéria' },
        { code: 'LY', name: 'Líbia' },
        { code: 'LI', name: 'Liechtenstein' },
        { code: 'LT', name: 'Lituânia' },
        { code: 'LU', name: 'Luxemburgo' },
        { code: 'MO', name: 'Macau' },
        { code: 'MK', name: 'Macedônia' },
        { code: 'MG', name: 'Madagascar' },
        { code: 'MW', name: 'Malawi' },
        { code: 'MY', name: 'Malásia' },
        { code: 'MV', name: 'Maldivas' },
        { code: 'ML', name: 'Mali' },
        { code: 'MT', name: 'Malta' },
        { code: 'MH', name: 'Ilhas Marshall' },
        { code: 'MQ', name: 'Martinica' },
        { code: 'MR', name: 'Mauritânia' },
        { code: 'MU', name: 'Maurício' },
        { code: 'YT', name: 'Mayotte' },
        { code: 'MX', name: 'México' },
        { code: 'FM', name: 'Micronésia' },
        { code: 'MD', name: 'Moldávia' },
        { code: 'MC', name: 'Mônaco' },
        { code: 'MN', name: 'Mongólia' },
        { code: 'MS', name: 'Montserrat' },
        { code: 'MA', name: 'Marrocos' },
        { code: 'MZ', name: 'Moçambique' },
        { code: 'MM', name: 'Mianmar' },
        { code: 'NA', name: 'Namíbia' },
        { code: 'NR', name: 'Nauru' },
        { code: 'NP', name: 'Nepal' },
        { code: 'NL', name: 'Países Baixos' },
        { code: 'AN', name: 'Antilhas Neerlandesas' },
        { code: 'NC', name: 'Nova Caledônia' },
        { code: 'NZ', name: 'Nova Zelândia' },
        { code: 'NI', name: 'Nicarágua' },
        { code: 'NE', name: 'Níger' },
        { code: 'NG', name: 'Nigéria' },
        { code: 'NU', name: 'Niue' },
        { code: 'NF', name: 'Ilha Norfolk' },
        { code: 'MP', name: 'Ilhas Marianas do Norte' },
        { code: 'NO', name: 'Noruega' },
        { code: 'OM', name: 'Omã' },
        { code: 'PK', name: 'Paquistão' },
        { code: 'PW', name: 'Palau' },
        { code: 'PS', name: 'Palestina' },
        { code: 'PA', name: 'Panamá' },
        { code: 'PG', name: 'Papua-Nova Guiné' },
        { code: 'PY', name: 'Paraguai' },
        { code: 'PE', name: 'Peru' },
        { code: 'PH', name: 'Filipinas' },
        { code: 'PN', name: 'Pitcairn' },
        { code: 'PL', name: 'Polônia' },
        { code: 'PT', name: 'Portugal' },
        { code: 'PR', name: 'Porto Rico' },
        { code: 'QA', name: 'Catar' },
        { code: 'RE', name: 'Reunião' },
        { code: 'RO', name: 'Romênia' },
        { code: 'RU', name: 'Rússia' },
        { code: 'RW', name: 'Ruanda' },
        { code: 'SH', name: 'Santa Helena' },
        { code: 'KN', name: 'São Cristóvão e Névis' },
        { code: 'LC', name: 'Santa Lúcia' },
        { code: 'PM', name: 'São Pedro e Miquelão' },
        { code: 'VC', name: 'São Vicente e Granadinas' },
        { code: 'WS', name: 'Samoa' },
        { code: 'SM', name: 'San Marino' },
        { code: 'ST', name: 'São Tomé e Príncipe' },
        { code: 'SA', name: 'Arábia Saudita' },
        { code: 'SN', name: 'Senegal' },
        { code: 'CS', name: 'Sérvia e Montenegro' },
        { code: 'SC', name: 'Seicheles' },
        { code: 'SL', name: 'Serra Leoa' },
        { code: 'SG', name: 'Singapura' },
        { code: 'SK', name: 'Eslováquia' },
        { code: 'SI', name: 'Eslovênia' },
        { code: 'SB', name: 'Ilhas Salomão' },
        { code: 'SO', name: 'Somália' },
        { code: 'ZA', name: 'África do Sul' },
        { code: 'GS', name: 'Geórgia do Sul e Ilhas Sandwich do Sul' },
        { code: 'ES', name: 'Espanha' },
        { code: 'LK', name: 'Sri Lanka' },
        { code: 'SD', name: 'Sudão' },
        { code: 'SR', name: 'Suriname' },
        { code: 'SJ', name: 'Svalbard e Jan Mayen' },
        { code: 'SZ', name: 'Suazilândia' },
        { code: 'SE', name: 'Suécia' },
        { code: 'CH', name: 'Suíça' },
        { code: 'SY', name: 'Síria' },
        { code: 'TW', name: 'Taiwan' },
        { code: 'TJ', name: 'Tadjiquistão' },
        { code: 'TZ', name: 'Tanzânia' },
        { code: 'TH', name: 'Tailândia' },
        { code: 'TL', name: 'Timor-Leste' },
        { code: 'TG', name: 'Togo' },
        { code: 'TK', name: 'Tokelau' },
        { code: 'TO', name: 'Tonga' },
        { code: 'TT', name: 'Trinidad e Tobago' },
        { code: 'TN', name: 'Tunísia' },
        { code: 'TR', name: 'Turquia' },
        { code: 'TM', name: 'Turcomenistão' },
        { code: 'TC', name: 'Ilhas Turcas e Caicos' },
        { code: 'TV', name: 'Tuvalu' },
        { code: 'UG', name: 'Uganda' },
        { code: 'UA', name: 'Ucrânia' },
        { code: 'AE', name: 'Emirados Árabes Unidos' },
        { code: 'GB', name: 'Reino Unido' },
        { code: 'US', name: 'Estados Unidos' },
        { code: 'UM', name: 'Ilhas Menores Distantes dos Estados Unidos' },
        { code: 'UY', name: 'Uruguai' },
        { code: 'UZ', name: 'Uzbequistão' },
        { code: 'VU', name: 'Vanuatu' },
        { code: 'VE', name: 'Venezuela' },
        { code: 'VN', name: 'Vietnã' },
        { code: 'VG', name: 'Ilhas Virgens Britânicas' },
        { code: 'VI', name: 'Ilhas Virgens Americanas' },
        { code: 'WF', name: 'Wallis e Futuna' },
        { code: 'EH', name: 'Saara Ocidental' },
        { code: 'YE', name: 'Iémen' },
        { code: 'ZM', name: 'Zâmbia' },
        { code: 'ZW', name: 'Zimbábue' }
      ],
      availableLanguages: [
        'en', 'pt_BR', 'es'
      ]
    }
  }
}

export default variables
