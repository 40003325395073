<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ pageTitle }}</h1>
        <!--end::Title-->
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link :to="{ name: 'companies.show', params: { id: company.id } }"
            class="btn btn-sm btn-primary" v-if="company">
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <form @submit.prevent="" class="text-left default-form">
          <LoaderOverlay
            size="big"
            color="color2"
            v-if="overlayLoader"
          />
          <div class="d-flex align-items-center gap-5">
            <div class="form-group mt-8 mb-6 col-12 col-md-5">
              <label class="form-label fw-bold">Nacionalidade</label>
              <select class="form-select" v-model.trim="form.country">
                <option disabled selected value="">Selecione</option>
                <option v-for="(country, index) in parsedCountries" :key="index" :value="country.code"
                  :selected="country.code === form.country">{{ country.name }}</option>
              </select>
            </div>
            <div class="form-group mb-3" v-if="!isEditPage">
              <label class="form-label fw-bold">Empresa vinculada*</label><br/>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model.trim="form.type" id="registration-type-company" value="ADVERTISER">
                <label class="form-check-label" for="registration-type-company">Próprio anunciante</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model.trim="form.type" id="registration-type-agency" value="AGENCY">
                <label class="form-check-label" for="registration-type-agency">Agência</label>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row gap-5">
            <div class="form-group mb-3 col-12 col-md-5">
              <label class="form-label fw-bold">Razão Social </label>
              <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.name" />
            </div>
            <div class="form-group mb-3 col-12 col-md-5">
              <label class="form-label fw-bold">Nome Fantasia </label>
              <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.fantasy_name" />
            </div>
          </div>
          <div class="form-group mb-5" v-if="form.country === 'BR'">
            <label class="form-label fw-bold col-12 col-md-5">CNPJ/CPF*</label>
            <the-mask class="form-control" placeholder="CNPJ/CPF" :mask="['###.###.###-##', '##.###.###/####-##']"
              v-model.trim="form.company_document" />
          </div>
          <div class="form-group mb-5" v-else>
            <label class="form-label fw-bold col-12 col-md-5">Tax-ID*</label>
            <input type="text" class="form-control" placeholder="Tax-ID" v-model.trim="form.company_document" />
          </div>
          <div class="mb-5" v-if="form.country === 'BR'">
            <div class="position-relative">
              <LoaderOverlay size="big" v-if="zipcodeLoader" />
              <div class="d-lg-flex mb-3">
                <div class="col-lg-4 pl-0">
                  <div class="form-group">
                    <label class="form-label fw-bold">CEP*</label>
                    <TheMask :mask="'#####-###'" v-model.trim="form.address.zipcode" placeholder="" class="form-control"
                      @change.native="getZipcode" />
                  </div>
                </div>
                <div class="col-lg-8 px-0 ps-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Rua*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.street" placeholder=""
                      :readonly="readonlyForm" />
                  </div>
                </div>
              </div>
              <div class="d-lg-flex mb-3">
                <div class="col-lg-4 pl-0">
                  <div class="form-group">
                    <label class="form-label fw-bold">Número*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.number" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 pl-0 px-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Complemento</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.complement"
                      placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 px-0">
                  <div class="form-group">
                    <label class="form-label fw-bold">Bairro*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.neighborhood" placeholder=""
                      :readonly="readonlyForm" />
                  </div>
                </div>
              </div>
              <div class="d-lg-flex">
                <div class="col-lg-5 pl-0 pe-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Cidade*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.city" placeholder=""
                      readonly />
                  </div>
                </div>
                <div class="col-lg-5 px-0 pe-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Estado*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.state" placeholder=""
                      readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-8">
            <Button @click="submit" class="btn btn-primary" :loading="loader">
              {{ buttonText }}
            </Button>
          </div>
        </form>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import CompaniesService from '@/modules/companies/services/companies-service'
import { getZipcodeData, parseErrorResponseToArray } from '@/utils'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import { TheMask } from 'vue-the-mask'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  name: 'CompanyEdit',
  mixins: [variables],
  components: {
    Button,
    LoaderOverlay,
    MainLayout,
    TheMask
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  data () {
    return {
      company: null,
      companies: [],
      form: {
        country: '',
        name: '',
        fantasy_name: '',
        company_document: '',
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      loader: false,
      overlayLoader: false,
      readonlyForm: true,
      selectedOption: '',
      zipcodeLoader: false,
      companiesLoader: false
    }
  },
  created () {
    if (this.isEditPage) {
      this.getCompany()
    }
  },
  computed: {
    buttonText () {
      return this.company ? 'Salvar' : 'Criar empresa'
    },
    companiesOptions () {
      const processedIds = new Set()
      return this.companies
        .filter(company => !processedIds.has(company.id) && processedIds.add(company.id))
        .map(company => ({ label: `${company.name}`, code: company.id }))
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'companies.edit'
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'companies.create' ? 'Criar empresa' : 'Editar empresa'
    },
    /**
     * parse countries list
     */
    parsedCountries () {
      const countries = this.countries.map(item => {
        return {
          code: item.code,
          name: item.name
        }
      })

      countries.sort((a, b) => a.name.localeCompare(b.name))

      return countries
    }
  },
  methods: {
    /**
     * Get company
     */
    async getCompany () {
      this.overlayLoader = true
      try {
        const company = await CompaniesService.getCompany(this.$route.params.id)
        this.company = company
        this.setCompany(company)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    },
    /**
     * Get zipcode data given a zipcode
     */
    async getZipcode () {
      this.zipcodeLoader = true
      this.content = null
      try {
        const address = await getZipcodeData(this.form.address.zipcode)
        this.form.address.street = address.street
        this.form.address.neighborhood = address.neighborhood
        this.form.address.city = address.city
        this.form.address.state = address.state

        this.readonlyForm = !!address.street
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.zipcodeLoader = false
    },
    /**
     * Set company data to form
     */
    setCompany () {
      this.form.country = this.company.country
      this.form.name = this.company.name
      this.form.fantasy_name = this.company.fantasy_name
      this.form.company_document = this.company.document
      this.form.address.zipcode = this.company.address.zipcode
      this.form.address.street = this.company.address.street
      this.form.address.number = this.company.address.number
      this.form.address.complement = this.company.address.complement
      this.form.address.neighborhood = this.company.address.neighborhood
      this.form.address.city = this.company.address.city
      this.form.address.state = this.company.address.state
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      try {
        const form = Object.assign({}, this.form)

        const company = await CompaniesService.updateCompany(this.company.id, form)

        this.$router.push({ name: 'companies.show', params: { id: company.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
